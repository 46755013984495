<template>
    <div class="Page solutions">
        <banner />
        <support />
        <finding />
    </div>
</template>

<script>
import banner from './components/banner.vue';
import support from './components/support.vue';
import finding from './components/finding.vue';

export default {
    name: 'solutions',
    data() {
        return {
            pageDurationID: 'Solution',
        };
    },
    components: {
        banner,
        support,
        finding,
    },
    mounted() {
        this.$track('solutions_imp');
    },
};
</script>

<style lang="scss">
.Page.solutions {
    background: #fff;
}
</style>
