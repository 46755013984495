<template>
    <div class="solutions-finding">
        <div class="block-title solutions-finding-ani poppins solutions-finding-title">{{ i18nPage.title }}</div>
        <div class="solutions-finding-list">
            <findingItem :index="index" :data="item" v-for="(item, index) in data" :key="index" />
        </div>

        <div class="solutions-finding-select" ref="select">
            <v-select :reduce="i => i.code" class="v-select v-select1" :placeholder="i18nPage.companyType" v-model="a" :options="aData" :selectable="aSelectable">
                <template #no-options>
                    <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                </template>
                <template #open-indicator="{ attributes }">
                    <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                        <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                    </svg>
                </template>
            </v-select>
            <v-select :reduce="i => i.code" class="v-select v-select2" :placeholder="i18nPage.businessType" v-model="b" :options="bData" :selectable="bSelectable">
                <template #no-options>
                    <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                </template>
                <template #open-indicator="{ attributes }">
                    <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                        <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                    </svg>
                </template>
            </v-select>
            <v-select :reduce="i => i.code" class="v-select v-select3" :placeholder="i18nPage.productInterest" v-model="c" :options="cData" :selectable="cSelectable">
                <template #no-options>
                    <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                </template>
                <template #open-indicator="{ attributes }">
                    <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                        <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                    </svg>
                </template>
            </v-select>
            <a href="javascript:;" class="poppins button" @click="onSend">{{ i18nPage.send }}</a>
        </div>

        <div class="solutions-finding-product" v-if="productArr && productArr.length">
            <div class="solutions-finding-product-item" v-for="item in productArr" :key="item">
                <div class="solutions-finding-product-item-img" :class="[`img${item}`]"></div>
                <div class="solutions-finding-product-item-info">
                    <a href="javascript:;" class="poppins name" @click="onProduct(item)">{{ i18nPage[item].name }}<span class="icon"></span></a>
                    <div class="desc">{{ i18nPage[item].desc }}</div>
                    <div class="descs" v-if="i18nPage[item].descs">
                        <div class="descs-item" v-for="(list, listIndex) in i18nPage[item].descs" :key="listIndex">
                            {{ list }}
                        </div>
                    </div>
                    <div class="buttons">
                        <a href="javascript:;" class="poppins button" @click="onContact(item)">{{ i18nPage.contactUs }}</a>
                        <a href="javascript:;" v-if="item === '3'" class="poppins button button2" @click="onTryDemo(item)">{{ i18nPage.tryDemo }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="solutions-finding-contact" ref="contact" v-if="productArr && productArr.length">
            <div class="block-title poppins solutions-finding-contact-title">{{ i18nPage.contactTitle }}</div>
            <contactForm :isShowDescription="false" @success="onContactSuccess" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import findingItem from './findingItem.vue';
import contactForm from '../../components/contactForm.vue';
export default {
    name: 'solutionsFinding',
    components: {
        vSelect,
        findingItem,
        contactForm,
    },
    data() {
        var productMap = {
            1: 'eve',
            2: 'eveV',
            3: 'skin-mirror',
            4: 'meitukey',
            5: 'virtual-makeup',
            6: 'virtual-hair-dye',
            7: 'accessories',
            8: 'contact-lenses',
        };
        var all = Object.keys(productMap);
        return {
            a: '',
            b: '',
            c: '',
            productArr: [],
            productMap,
            dataMap: {
                a1_b1_c1: ['3'],
                a1_b1_c2: ['3', '1', '4'],
                a1_b2_c1: ['5'],
                a1_b2_c2: ['5'],
                a1_b3_c1: ['7'],
                a1_b3_c2: ['7'],
                a1_b4_c1: ['8'],
                a1_b4_c2: ['8'],
                a2_b1_c1: ['3'],
                a2_b1_c2: ['3', '1', '2', '4'],
                a3_b1_c2: ['1', '2', '4'],
                a4_b1_: all,
                a4_b2_: all,
                a4_b3_: all,
                a4_b4_: all,
                a5_b1_c1: ['3'],
                a5_b1_c2: ['3', '1', '4'],
                a5_b2_c1: ['5'],
                a5_b2_c2: ['5'],
                a5_b3_c1: ['7'],
                a5_b3_c2: ['7'],
                a5_b4_c1: ['8'],
                a5_b4_c2: ['8'],
                a6__: all,
            },
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('solutionsPage.finding');
        },
        data() {
            var i18n = this.i18nPage;
            return [
                {
                    ...i18n.eve,
                    list: [
                        {
                            icon: require('@assets/images/solutions/finding/beauty.svg'),
                            name: i18n.beauty,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/salon.svg'),
                            name: i18n.salon,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/clinic.svg'),
                            name: i18n.clinic,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/store.svg'),
                            name: i18n.store,
                        },
                    ],
                },
                {
                    ...i18n.makeup,
                    list: [
                        {
                            icon: require('@assets/images/solutions/finding/makeup.svg'),
                            name: i18n.imakeup,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/hairDye.svg'),
                            name: i18n.hairDye,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/accessories.svg'),
                            name: i18n.accessories,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/contactLenses.svg'),
                            name: i18n.contactLenses,
                        },
                    ],
                },
                {
                    ...i18n.business,
                    list: [
                        {
                            icon: require('@assets/images/solutions/finding/store.svg'),
                            name: i18n.inStore,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/h5.svg'),
                            name: i18n.h5,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/mini.svg'),
                            name: i18n.mini,
                        },
                        {
                            icon: require('@assets/images/solutions/finding/cart.svg'),
                            name: i18n.onlineStore,
                        },
                    ],
                },
                {
                    ...i18n.system,
                },
                {
                    ...i18n.customized,
                },
            ];
        },
        aData() {
            var i18n = this.i18nPage;
            return [
                {
                    label: i18n.company1,
                    code: 'a1',
                },
                {
                    label: i18n.company2,
                    code: 'a2',
                },
                {
                    label: i18n.company3,
                    code: 'a3',
                },
                {
                    label: i18n.company4,
                    code: 'a4',
                },
                {
                    label: i18n.company5,
                    code: 'a5',
                },
                {
                    label: i18n.company6,
                    code: 'a6',
                },
            ];
        },
        bData() {
            var i18n = this.i18nPage;
            return [
                {
                    label: i18n.business1,
                    code: 'b1',
                },
                {
                    label: i18n.business2,
                    code: 'b2',
                },
                {
                    label: i18n.business3,
                    code: 'b3',
                },
                {
                    label: i18n.business4,
                    code: 'b4',
                },
            ];
        },
        cData() {
            var i18n = this.i18nPage;
            return [
                {
                    label: i18n.product1,
                    code: 'c1',
                },
                {
                    label: i18n.product2,
                    code: 'c2',
                },
            ];
        },
    },
    methods: {
        scrollElToTop(ref) {
            var el = this.$refs[ref];
            if (el) {
                let offsetTop = el.offsetTop;
                let header = document.getElementById('page-header');
                window.scrollTo({
                    left: 0,
                    top: offsetTop - header.getBoundingClientRect().height,
                    behavior: 'smooth',
                });
            }
        },
        onContactSuccess(data) {
            if (this.currentLang === 'zh') {
                this.$toast.center(this.$t('contactSuccessToast'));
            } else {
                this.$router.push({
                    name: 'contact',
                    params: {
                        showMeeting: true,
                        data,
                    },
                });
            }
        },
        onProduct(id) {
            this.$router.push({
                path: `/${this.$route.params.lang}/product/${this.productMap[id]}`,
                query: {
                    ...this.$route.query,
                },
            });
        },
        onContact(id) {
            this.$track('solutions_product_clk', {
                type: id,
            });
            this.scrollElToTop('contact');
        },
        onTryDemo(id) {
            // 跳转自助下单
            if (id === '3') {
                location.href = process.env.VUE_APP_BP_HOST + '?spm=global-solutions#/auth/signup';
            }
        },
        aSelectable(cur) {
            var data = Object.keys(this.dataMap);
            if (this.b) {
                data = data.filter(i => {
                    var split = i.split('_');
                    return split[1] === this.b;
                });
            }
            if (this.c) {
                data = data.filter(i => {
                    var split = i.split('_');
                    return split[2] === this.c;
                });
            }
            data = data.filter(i => {
                var split = i.split('_');
                return cur.code === split[0];
            });
            return data.length > 0;
        },
        bSelectable(cur) {
            var data = Object.keys(this.dataMap);
            if (this.a) {
                data = data.filter(i => {
                    var split = i.split('_');
                    return split[0] === this.a;
                });
            }
            if (this.c) {
                data = data.filter(i => {
                    var split = i.split('_');
                    return split[2] === this.c;
                });
            }
            data = data.filter(i => {
                var split = i.split('_');
                return cur.code === split[1];
            });
            return data.length > 0;
        },
        cSelectable(cur) {
            var data = Object.keys(this.dataMap);
            if (this.a) {
                data = data.filter(i => {
                    var split = i.split('_');
                    return split[0] === this.a;
                });
            }
            if (this.b) {
                data = data.filter(i => {
                    var split = i.split('_');
                    return split[1] === this.b;
                });
            }
            data = data.filter(i => {
                var split = i.split('_');
                return cur.code === split[2];
            });
            return data.length > 0;
        },
        onSend() {
            let { a, b, c } = this;
            // a 是必填
            // a !== a6 时，b 是必填
            if (!a) {
                this.$toast.center(this.i18nPage.selectCompanyType);
                return;
            }
            if (!b && a !== 'a6') {
                this.$toast.center(this.i18nPage.selectBusinessType);
                return;
            }
            if (!c && !this.dataMap[`${a}_${b}_${c}`]) {
                this.$toast.center(this.i18nPage.selectProductInterest);
                return;
            }
            this.$track('solutions_submit_clk', {
                company_type: a,
                business_type: b,
                interest_services: c,
            })
            this.productArr = this.dataMap[`${a}_${b}_${c}`];
            this.$nextTick(() => {
                this.scrollElToTop('select');
            });
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.solutions-finding-ani',
            triggerElement: '.solutions-finding-ani',
        });
    },
};
</script>

<style lang="scss">
.solutions-finding {
    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        &-list {
            width: 1080px;
            margin: 40px auto 0 auto;
        }
        &-select {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 80px;
            .v-select {
                background: #fff;
                margin-right: 30px;
                &1 {
                    width: 280px;
                }
                &2 {
                    width: 220px;
                }
                &3 {
                    width: 490px;
                }
                .vs__selected {
                    font-size: 14px;
                    font-weight: 500;
                }
                .vs__dropdown-toggle {
                    min-height: 56px;
                    box-sizing: border-box;
                    border-color: rgba(0, 0, 0, 0.15);
                }
                .vs__dropdown-option--disabled {
                    cursor: not-allowed;
                }
                .vs__dropdown-menu {
                    border-color: #000;
                }
                &.vs--open {
                    .vs__dropdown-toggle {
                        border-color: #000;
                    }
                }
                .vs__search {
                    // display: none;
                    // opacity: 0.1;
                    &::placeholder {
                        color: #999;
                    }
                }
                .vs__dropdown-option {
                    white-space: normal;
                }
                &.vs--open {
                    .vs__selected {
                        height: 100%;
                        // position: relative;
                    }
                }
                .vs__actions {
                    padding-right: 15px;
                }
            }
            .button {
                padding: 0 32px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #000;
                color: #fff;
                border-radius: 30px;
                font-size: 18px;
                font-weight: 500;
            }
        }
        &-product {
            width: 1080px;
            margin: 0 auto;

            &-item {
                display: flex;
                margin-bottom: 80px;

                &-img {
                    width: 474px;
                    height: 317px;
                    flex-shrink: 0;
                    margin-right: 100px;
                    border-radius: 20px;

                    &.img1 {
                        background: url(~@assets/images/solutions/finding/product/1.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img2 {
                        background: url(~@assets/images/solutions/finding/product/2.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img3 {
                        background: url(~@assets/images/solutions/finding/product/3.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img4 {
                        background: url(~@assets/images/solutions/finding/product/4.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img5 {
                        background: url(~@assets/images/solutions/finding/product/5.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img6 {
                        background: url(~@assets/images/solutions/finding/product/6.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img7 {
                        background: url(~@assets/images/solutions/finding/product/7.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img8 {
                        background: url(~@assets/images/solutions/finding/product/8.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                }
                &-info {
                    .name {
                        font-size: 24px;
                        line-height: 28.8px;
                        font-weight: 500;
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        color: #000;

                        .icon {
                            width: 9px;
                            height: 14px;
                            margin-left: 8px;
                            background: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.95 11.95l4.775-4.775a.248.248 0 0 0-.002-.352L1.95 2.05' stroke='%23000' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
                            background-size: contain;
                        }
                    }
                    .desc {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    .descs {
                        margin-top: 10px;
                        &-item {
                            font-size: 18px;
                            line-height: 25px;
                            margin-bottom: 10px;
                            position: relative;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                            &:before {
                                content: '';
                                width: 4px;
                                height: 4px;
                                border-radius: 4px;
                                position: absolute;
                                left: -14px;
                                top: 11px;
                                background: #000;
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        margin-top: 40px;
                    }
                    .button {
                        font-size: 18px;
                        height: 50px;
                        border-radius: 25px;
                        padding: 0 30px;
                        color: #fff;
                        background: #000;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 25px;
                        white-space: nowrap;
                    }
                    .button2 {
                        background: transparent;
                        color: #000;
                        border: solid 1px #000;
                    }
                }
            }
        }
        &-contact {
            background: #f9f9f9 url("data:image/svg+xml,%3Csvg width='724' height='298' viewBox='0 0 724 298' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.4' fill-rule='evenodd' clip-rule='evenodd' d='M-441.821 418.918c-75.772 233.043-9.548 489.888 168.723 654.372 32.05 29.56 81.215 26.7 109.831-6.41 28.621-33.06 25.865-83.839-6.169-113.4-131.842-121.616-180.87-311.374-124.926-483.437 77.205-237.45 328.308-365.328 559.767-285.079 231.46 80.25 356.951 338.698 279.746 576.149-55.99 172.204-206.134 292.957-382.472 307.677-42.839 3.56-74.737 42.31-71.295 86.53 2.589 33.02 24.196 59.79 52.813 69.71a75.17 75.17 0 0 0 30.987 3.89c238.492-19.89 441.588-183.43 517.427-416.681C797.12 490.811 627.709 141.106 314.926 32.661 2.158-75.78-337.311 97.489-441.821 418.918z' fill='url(%23paint0_linear_2057_46130)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2057_46130' x1='-618.018' y1='960.78' x2='523.639' y2='1331.98' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23AAE5FF'/%3E%3Cstop offset='1' stop-color='%23B1F6FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") left bottom no-repeat;
            padding: 80px 0;

            .contact-form {
                width: 683px;
                margin: 40px auto 0 auto;
                background: #fff;
                border-radius: 20px;
                padding-bottom: 60px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding-top: 0.6rem;

        .vs__dropdown-option {
            white-space: normal;
        }

        &-title {
            padding: 0 0.35rem;
            font-size: 0.28rem;
            line-height: 0.32rem;
            font-weight: 600;
            text-align: center;
            margin-bottom: 0.47rem;
        }
        &-list {
            padding: 0 0.35rem;
        }
        &-select {
            display: flex;
            flex-direction: column;
            padding-bottom: 0.6rem;
            .v-select {
                width: 2.5rem;
                margin: 0 auto 0.22rem auto;

                .vs__dropdown-toggle {
                    min-height: 48px;
                }
                .vs__selected + .vs__search {
                    height: 0 !important;
                    margin: 0 !important;
                    border: 0 !important;
                }
                .vs__actions {
                    padding-right: 0.2rem;
                }
            }
            .button {
                height: 0.42rem;
                border-radius: 0.21rem;
                padding: 0 0.37rem;
                font-size: 0.15rem;
                font-weight: 600;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #000;
                margin: 0 auto;
            }
        }
        &-product {
            &-item {
                margin-bottom: 0.6rem;
                &-img {
                    width: 3.05rem;
                    height: 2.03rem;
                    margin: 0 auto 0.2rem auto;
                    border-radius: 0.12rem;

                    &.img1 {
                        background: url(~@assets/images/solutions/finding/product/m1.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img2 {
                        background: url(~@assets/images/solutions/finding/product/m2.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img3 {
                        background: url(~@assets/images/solutions/finding/product/m3.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img4 {
                        background: url(~@assets/images/solutions/finding/product/m4.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img5 {
                        background: url(~@assets/images/solutions/finding/product/m5.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img6 {
                        background: url(~@assets/images/solutions/finding/product/m6.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img7 {
                        background: url(~@assets/images/solutions/finding/product/m7.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                    &.img8 {
                        background: url(~@assets/images/solutions/finding/product/m8.jpg) 0 0 no-repeat;
                        background-size: contain;
                    }
                }
                &-info {
                    display: flex;
                    flex-direction: column;
                    padding: 0 0.35rem;
                    .name {
                        color: #000;
                        font-size: 0.2rem;
                        font-weight: 600;
                        margin-bottom: 0.05rem;
                        display: flex;
                        align-items: center;

                        .icon {
                            width: 0.05rem;
                            height: 0.09rem;
                            margin-left: 0.08rem;
                            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABbSURBVHgBzdDBDQAREIXh2d0CdkvYknRACTpQIh2ISngHB5mIIS4k/2GSL8IQLZxfAg5lFEc4VjSEpkEnwIehgBJSdf7QdXduy2x+OdDSu/aBoomve5rYjZVAAeyiMqbr2y4jAAAAAElFTkSuQmCC") 0 0 no-repeat;
                            background-size: contain;
                            flex-shrink: 0;
                        }
                    }
                    .desc {
                        font-size: 0.13rem;
                        line-height: 0.18rem;
                    }
                    .descs {
                        margin-top: 0.15rem;
                        &-item {
                            position: relative;
                            margin-bottom: 0.1rem;
                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                width: 0.04rem;
                                height: 0.04rem;
                                border-radius: 0.04rem;
                                background: #000;
                                left: -0.12rem;
                                top: 0.07rem;
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        margin-top: 0.2rem;
                    }
                    .button {
                        height: 0.4rem;
                        border-radius: 0.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #000;
                        color: #fff;
                        padding: 0 0.2rem;
                        font-size: 0.12rem;
                        font-weight: 500;
                        margin-right: 0.25rem;
                        white-space: nowrap;
                    }
                    .button2 {
                        background: transparent;
                        border: solid 1px #000;
                        color: #000;
                    }
                }
            }
        }

        &-contact {
            background: #f9f9f9 url("data:image/svg+xml,%3Csvg width='194' height='129' viewBox='0 0 194 129' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.4' fill-rule='evenodd' clip-rule='evenodd' d='M81.587 478.667c78.993 78.993 197.341 103.138 301.518 61.514 18.727-7.485 27.833-28.723 20.344-47.444-7.474-18.717-28.712-27.834-47.434-20.355-77.033 30.789-164.491 12.993-222.813-45.329-80.487-80.487-80.053-211.871.963-292.888 81.017-81.017 212.401-81.45 292.888-.963 58.371 58.37 76.134 145.892 45.276 222.963-7.501 18.722 1.6 39.965 20.311 47.465 13.979 5.595 29.386 1.933 39.403-8.084a36.375 36.375 0 0 0 8.057-12.233c41.737-104.235 17.63-222.663-61.433-301.726C369.715-27.365 192.022-26.943 82.54 82.54c-109.477 109.477-109.905 287.175-.953 396.127z' fill='url(%23paint0_linear_2415_18985)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2415_18985' x1='265.255' y1='662.344' x2='662.345' y2='265.254' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23AAE5FF'/%3E%3Cstop offset='1' stop-color='%23B1F6FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") right bottom no-repeat;
            padding: 0.6rem 0;

            &-title {
                font-size: 0.28rem;
                text-align: center;
                line-height: 0.33rem;
                font-weight: 700;
                padding: 0 0.46rem;
                margin-bottom: 0.35rem;
            }
            .contact-form {
                padding: 0 0.35rem;
            }
        }
    }
}
</style>
