<template>
    <div class="solutions-support">
        <div class="block-title solutions-support-ani poppins solutions-support-title">{{ i18nPage.title }}</div>
        <div class="block-desc solutions-support-ani solutions-support-desc">{{ i18nPage.desc }}</div>
    </div>
</template>

<script>
export default {
    name: 'solutionsSupport',
    computed: {
        i18nPage() {
            return this.$t('solutionsPage.support');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.solutions-support-ani',
            triggerElement: '.solutions-support',
        });
    },
};
</script>

<style lang="scss">
.solutions-support {
    background: #f9f9f9;
    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        text-align: center;

        &-desc {
            width: 800px;
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0.35rem;
        text-align: center;
        &-title {
            font-size: 0.28rem;
            line-height: 0.32rem;
            margin-bottom: 0.2rem;
            font-weight: 600;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.18rem;
        }
    }
}
</style>
