<template>
    <div class="solutions-banner">
        <swiper class="solutions-banner-swiper" :options="swiperOption" :style="isPreRender ? 'opacity: 0;' : ''">
            <swiper-slide class="solutions-banner-swiper-item" :class="[`item${index + 1}`]" v-for="(item, index) in i18nPage.list" :key="index">
                <div class="content">
                    <div class="banner-info info">
                        <h1 class="banner-info-title poppins title">{{ item.title }}</h1>
                        <div class="banner-info-desc desc">{{ item.desc }}</div>
                    </div>
                    <img v-if="index === 0" :src="require(`@assets/images/solutions/banner/img_${currentLang}.png`)" alt="" class="img hidden-xs-only" />
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
    name: 'solutionsBanner',
    components: {
        swiper,
        swiperSlide,
    },
    data() {
        let width = window.innerWidth;
        return {
            isPreRender: window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.isPreRender,
            showSwiper: true,
            swiperOption: {
                loop: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 6500,
                    disableOnInteraction: false,
                },
                speed: 600,
                allowTouchMove: width < 769,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        };
    },
    watch: {
        currentLang() {
            // 重复的swiper语言没切换过来
            this.showSwiper = false;
            this.$nextTick(() => {
                this.showSwiper = true;
            });
        },
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('solutionsPage.banner');
        },
    },
};
</script>

<style lang="scss">
.solutions-banner {
    position: relative;
    .swiper-pagination-bullets {
        left: 50%;
        bottom: 40px;
        transform: translate(-50%, 0);
        font-size: 0;
    }
    .swiper-pagination-bullet {
        margin: 0 5px;
        opacity: 1;
        background: #e6e6e6;
        transition: all 0.4s;
        outline: none;
    }
    .swiper-pagination-bullet-active {
        transform: scale(1.1);
        background: #000;
    }
    @media only screen and (min-width: 769px) {
        &-swiper {
            height: 609px;
        }
        .banner-info {
            width: 540px;
        }
        .content {
            height: 100%;
            max-width: 1108px;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
        .item1 {
            background: url(~@assets/images/solutions/banner/1.jpg) center no-repeat;
            background-size: cover;

            .img {
                display: block;
                width: 655px;
                margin-top: auto;
            }
        }
        .item2 {
            background: url(~@assets/images/solutions/banner/2.jpg) center no-repeat;
            background-size: cover;

            .content {
                .info {
                    .desc {
                        margin-bottom: 70px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .swiper-pagination-bullets {
            bottom: 0.46rem;
        }
        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            margin: 0 7px;
        }
        .swiper-pagination-bullet-active {
            transform: scale(1.1);
        }
        &-swiper {
            &-item {
                width: 3.75rem;
                height: 6.46rem;
            }
        }
        .item1 {
            background: url(~@assets/images/solutions/banner/m1.jpg);
            background-size: contain;
        }
        .item2 {
            background: url(~@assets/images/solutions/banner/m2.jpg);
            background-size: contain;
        }
        .content {
            padding: 0.6rem 0.35rem 0 0.35rem;
            .title {
                font-size: 0.3rem;
                line-height: 1.1;
                width: 2.76rem;
                margin-bottom: 0.2rem;
                font-weight: 700;
            }
            .desc {
                font-size: 0.14rem;
                line-height: 0.2rem;
            }
        }
    }
}
</style>
